.content {
  @media (min-width: 768px) {
    --margin-top-page: calc(var(--d-header-height) + var(--d-body-padding));
    margin: 0;
    margin-top: var(--margin-top-page);
    min-height: calc(100vh - var(--margin-top-page) - var(--d-body-padding));
  }
  margin-top: calc(
    (var(--d-mobile-header-height) + var(--d-mobile-body-padding))
  );
  margin-bottom: calc(
    var(--d-mobile-nav-height) + var(--d-mobile-body-padding) 
  );
}

#router-outlet {
  --content-padding: 20px;
  padding: 0 var(--content-padding);
  height: inherit;
  @media (min-width: 767px) {
    padding: 0 var(--router-outlet-padding);
  }
  min-height: calc(
    100vh - var(--margin-top-page) - var(--d-body-padding) -
      var(--content-padding)
  );
}
.tg-header-wrapper {
  .tg-dropdown-list{
    box-shadow : unset;
  }
}
.mobile-menu-wrapper {
  .tg-dropdown-list {
    box-shadow: unset;
  }
}

.bottom-border {
  position: fixed;
  left: 0px;
  padding-left: var(--d-nav-width);
  height: var(--d-body-padding);
  width: 100%;
  background-color: var(--primary);
  z-index: calc(var(--z-nav) - 1);
  bottom: var(--d-mobile-nav-height);
  @media (min-width: 768px) {
    bottom: 0px;
  }

  .border-background {
    background-color: var(--primary);
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: var(--d-body-padding);
  }
  .right {
      right: 10px;
  }
  .left {
    @media (min-width: 768px) {
      left: 100px;
    }
    left: 10px;
  }
  .b-right,
  .b-left {
    width: 100%;
    height: 100%;
    background-color: var(--main-bg);
  }
  .b-right {
    border-radius: 0 0 100%;
  }
  .b-left {
    border-radius: 0 0 0 100%;
  }
}
