.grid-wrapper {
    --tg-grid-font-size: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    --tg-grid-primary: var(--primary);
    --tg-grid-header-foreground-color:var(--primary);
    .tg-grid-container {
      padding: 0 !important;
      font-family: "Averta";
    }
    .tg-MUI-grid-wrapper {
      //for Firefox  support
      scrollbar-color: lightgray transparent;
      scrollbar-width: thin;
      //for Chrome, Microsoft, Safari support
      &::-webkit-scrollbar-thumb {
        background-color: lightgray;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
    .tg-grid-header-search 
    .tg-input-control 
      input {
        overflow: hidden;
        text-overflow: ellipsis;
      }
  }