#root {
    &:has(.unauth-block){
          margin: 10px !important;
          padding-bottom: 0px;
    }
}
.unauth-block{
    height: calc(100vh - 20px);
    width: calc(100vw - 20px);
    font-weight: 600;
    background-color: var(--primary);
    .unauth-container{
        border-radius: 30px;
        width: 100%;
        height: 100%;
        .unauth-inner-container {
            position: relative;
            .unauth-logo{
                position: fixed;
                top : 25px;
                left: 25px;
            }
            .unauth-title{
                color: var(--primary);
                font-size: 24px;
            }
            .unauth-sub-title{
                color: #6C6969;
            }
            .unauth-button{
                border: var(--primary) 1px solid;
                border-radius: 8px;
                background-color: #F2F9FF;
                color: var(--primary);
                font-size: 14px;
                min-width: 458px;
            }
        }
    }
}