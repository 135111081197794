.modal-dialog{
    --bs-modal-header-border-width: 0px;
    margin-right: 10px !important;
    transition: none !important;
    transform: none;
    --bs-modal-footer-border-width: 0px;
    --bs-modal-margin: 1rem;
    --d-height: 122.5px;
    --bs-modal-width: 474px;
    --bs-modal-border-radius: 1.5rem;
    --input-bg: #F2F9FF;
    --input-hover:var(--input-bg);
    --input-border: var(--input-bg);
    margin-bottom: 0px !important;
    overflow: auto;
    .modal-content{
        border-radius: 0px var(--bs-modal-border-radius) var(--bs-modal-border-radius) 0px;
    }
    
    .filter-container{
        background-color: white;
        width: 100%;
        padding: 20px;
        margin-top: 20px;
    }
    .modal-footer{
        justify-content: space-between !important;
    }
    
}

@media (max-width: 768px){
.modal-dialog{
    margin-right: 0px;
}
.modal-content{
    border-radius: 0px !important;
}
.modal-body{
    height: calc(100vh - var(--d-height) - var(--d-mobile-nav-height) - 18px) !important;
    overflow-y:auto;
}
}
.fade{
    transition: none !important;
}
.modal-body{
    height: calc(100vh - var(--d-body-padding) - var(--d-height) - 8px);
    overflow:auto;
}
.card-label{
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.02em;
    text-align: left;
    position: relative;
    padding: 8px 0px 8px 20px;
  }

.card-decarator {
    position: absolute;
    background-color: var(--primary);
    border-radius: 0px 5px 5px 0px;
    left: 0%;
    top: 30px;
    bottom: auto;
    width: 5px;
    height: 30px;
    max-height: 40px;
    transform: translateY(-50%);
}
