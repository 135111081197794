.modal-dialog{
    .filter-criteria{
        display:flex;
        width: 100%;
        gap: 0.5rem;
        
    }
    .filter {
        .tg-input-control {
            min-height: auto !important;
        }
    }
    
    #filtersOutlet{
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .filter-dropdown{
        width: 21%;
        border-radius: 5px;
        background-color: #f2f9ff;
        height: fit-content;
        padding-bottom: 5px;
        .tg-dropdown-template{
            width: 100% ;
            --tg-dropdown-bg: var(--input-bg);
        }
        .tg-dropdown-control{
            background-color: #f2f9ff ;
        }
    }
    .filter-input{
        width: 85%;
        border-radius: 5px;
        display: flex;
        gap: 1rem;
        .new-input-control{
            border: none;
            width: 17rem;
            background-color: var(--input-bg);
        }
        .tags-input {
            display: inline-flex;
            flex-wrap: wrap;
            background-color: #f2f9ff;
            border-radius: 5px;
            flex-direction: column;
            .tg-input-control {
                display: inline-flex !important;
                overflow: hidden !important;
                width: auto !important;
                .tg-tags-container {
                    width: auto !important;
                }
            }
            .tag-search-input{
                flex-basis: auto;
            }
        }
    
    }
    .error-msg-tags-input{
        display: inline;
        .tag-search-input {
          border-bottom: 2px solid var(--error);
        }
      }
    .filter{
        width: 100%;
        margin-bottom: 10px;
    }
    .filtertag{
        margin-bottom: 10px;
    }
    .clear-filter{
        color: red;
        cursor: pointer;
        margin-top: 20px;
        text-decoration-line: underline;
    }
    .btn-primary {
        padding: 9px 18px !important;
        border-radius: 5px !important;
        font-weight: 400 !important;
        font-size: 10px !important;
    }
    .tg-button {
        background-color: var(--primary);
        color: white;
        outline: none;
        border: none;
        // padding: 10px;
        cursor: pointer;
        border-radius: 10px;
        margin-left: calc(var(--tg-modal-x-spacing) / 2);
    }
    .modal-cancel-button {
        background-color: #f2f9ff !important;
        color: var(--primary) !important;
    }
}