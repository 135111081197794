.fileUpload-container {
    .fileUpload{
        height: 250px;
        border: dotted #064a88;
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .drag-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.popup > .tg-input-group {
    width: 50% !important;
}
.upload-button{
    background-color: var(--primary);
    color: #ffffff;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 8px 18px;
    width: fit-content;
}
.grid-action-button {
    background-color: var(--primary);
    /* display: flex; */
    justify-content: center;
    align-items: center;
    color: #ffffff;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 8px 18px;
}
.files-container{
    .file{
        border: 1px solid;
    }
}
.file-details {
    display: inline-flex;
    border: 1px solid;
    padding: 10px;
    width: fit-content;
    border-radius: 20px;
    border-color: #064a88;
    .file-name {
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: fit-content;
    }
    .ext {
        text-transform: uppercase;
    }
    button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }
}
.popup{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.bottom-container{
    display: flex;
    align-items: baseline;
}
.expand{    
    justify-content: space-between;
}
.right{    
    justify-content:flex-end;
}

.actions-dropdown{
    display: flex;
    flex-direction: column;
    // width: fit-content;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    z-index: 100;

    button{
        background: none;
        border: none;
        cursor: pointer;
        outline: none;
        width:100%;
    }
    button:hover{
        background:#f2f9ff;
    }
    .dorpdown-list.expanded{
        margin-left: 10px;

    }
    
}
.action-icon{
    position: relative;
}
.documents{
    .MuiTableCell-root{
        overflow: visible !important;
    }
    .grid-action-button{
        display: inline-flex;
    }
    .grid-action-button:hover{
        background:#f2f9ff;
    }
    
}
.upload-document{
    margin: 10px;
    display: flex;
    justify-content:flex-end;
}
.actions-menu > .dropdown-wrapper > .tg-dropdown-parent.active> div:nth-child(2)
{
    top: 0;
    left:25%;
    position: absolute;
}
.error-msg{
    color:#ff5f5f
}

.actions-menu >  span {
    margin-right: 10px;
    display: inline-block;
    width: 24px;
    height: 24px;
}