.add-activity{
    margin: 10px;
    display: flex;
    justify-content:flex-end;
}

.note-column {
    max-width: 150px; 
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    position: relative;
    display: inline-block;
}
