@import "./styles/common.scss";
@import "@tg/react-ui-kit/dist/common-globals.css";
@font-face {
  font-family: "Averta";
  src: local("Averta"),
   url("./styles/fonts/AvertaDemo-Regular.ttf") format("truetype");
  }
html,
body {
  --primary: #064a88;
  --secondary: #ffce20;
  --active: #39b14a;
  --inactive: #ffce20;
  --on: #ff5f5f; //onhold
  --router-outlet-padding: 30px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #064a88;
  font-family: "Averta";

  //for Firefox support
  scrollbar-color: var(--secondary) #064a88;
  scrollbar-width: thin;
}

#root {
  --tg-dropdown-bg: #f3f3f3;
  background-color: #f3f3f3;
  --input-hover: white;
  margin: 0px;
margin: 0px 10px;

}

@media (min-width: 768px) {
  #root {
    margin: 20px 10px 0px var(--d-nav-width);
  }
}
.tg-grid-container .tg-grid-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.smart-filters {
  background-color: white;
  display: flex;
  color: #064a88;
  position: relative;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 7px;
  align-items: center;
  justify-content: center;
  .smart-filter-button{
    outline: none;
    border: none;
    color: #064a88;
    background-color: white;
  }
  &.showCount::before {
    content: attr(filter-count);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    font-weight: 600;
    color: white;
    font-size: 10px;
    border-radius: 50%;
    background: #E95744;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
  }
}
.error {
  padding-top: 2px;
  color: var(--error);
  font-size: 1rem;
  margin-top: 0.2rem;
  padding-left: 9px;
}
.overflow-wrapper.horizontal {
  margin-bottom: 0px;
  padding-bottom: 10px;
}

body.no-scroll-body {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

//scroll of side
.side-nav-bar-container .menu-items-wrapper {
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.no-access-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  font-size: 18px;
  color: red;
}
.loading-container {
  position: fixed;
  top: 0;
  left: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.loading-message {
  font-size: 18px;
}

.getting-data-spinner {
  position: absolute;
  margin-top: 0.30%;
  margin-left: 0.5%;

}
.emp-status {
  border-radius: 11px;
  color: white;
}
.active-status {
  padding: 4px 20px 4px 20px;
  border: 1px solid #5cb85c;
  background-color: #5cb85c;
}
.inactive-status {
  padding: 4px 15px 4px 15px;
  border: 1px solid #d9534f;
  background-color: #d9534f;
}
.roll-off-status {
  padding: 4px 15px 4px 15px;
  border: 1px solid #f0ad4e;
  background-color: #f0ad4e;
}

.date-picker-wrapper {
  background-color: inherit;
  
  .clear-date{
      padding: 0 4rem 0 0 !important;
  }
  .clear-date::after{
      background-color: white !important;
      font-size: 20px !important;
      color: #064A88 !important;
  }
}
.tg-input-group .tg-help-icon {
  top: unset;
}

#header-bar {
  display: none;
}

// This is the fix to hide unwanted scroll while document preview
#proxy-renderer {
  // overflow-x: hidden;
  word-wrap: break-word;
}
