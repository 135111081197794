.login-container {
    height: 100vh;
    background-color: var(--primary);
    flex-direction: column;
    @media (min-width: 768px) {
     flex-direction: row;
     .left-container{
      border-radius: 0px 30px 30px 0px;
     }
    }
  }
  
  #root {
      &:has(.login-container){
            margin: 0px !important;
            padding: 0px;
      }
  }
  .left-container,
  .right-container {
    flex: 1;
    align-items: stretch;
  }
  
  .left-container {
    border-radius: 0px 0px 30px 30px;
    .login-block{
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 18px;
        width: 80%;
        .login-header {
            font-weight: 600;
            font-size: 3.5rem;
            line-height: 52.81px;
            color: var(--primary);
            margin-bottom: 16px;
        }
        .login-outlook {
            border-radius: 5px;
            background-color: var(--primary);
            color: #FFFFFF;
            font-weight: 300;
            width: inherit;
            cursor: pointer;
        }
    }
  }
  
  .right-container {
    background-color: var(--primary);
    .image img {
        width: 100%;
        height: 20vh;
  
        @media (min-width: 768px) {
        height: auto;
        padding: 8px;
      }
    }
    .login-content {
      color: #ffffff;
      font-weight: 600;
      margin-top: 52px;
    }
    .login-title {
      font-size: 3.5rem;
      line-height: 52.81px;
    }
    .login-subtitle {
      font-size: 1.24rem;
      font-weight: 300;
      line-height: 19.2px;
    }
  }
  