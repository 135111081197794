.notification-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: fixed;
    width: 96.5%;
    top: 0;
    background-color: white;
    z-index: 1;
    color: var(--primary);
    padding: 12px 16px;
  }
  
  .tg-notification {
    .dorpdown-list {
      left: 50%;
      top: 36px;
      transform: translateX(-50%);
      padding-top: 0px;
      width: auto !important;
    }
  }
  
  .full-screen-expanded .notification-list {
    height: 100%;
    margin-right: 10px;
  }
  
  .tg-notification {
    .dorpdown-list.expanded::before {
      content: " ";
      position: absolute;
      width: 20px;
      height: 20px;
      background: white;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      top: 0;
      left: 50%;
      box-shadow: var(--dropdown-shadow);
    }
  
    .dorpdown-list.expanded {
      .tg-dropdown-list {
        padding-top: 0px;
        max-height: none;
      }
    }
  }
  
  .buzz-notification-list {
    width: 440px;
    padding: 0px;
    height: 420px;
    background-color: white;
    display: flex;
    flex-direction: column;
    
  
    .notification-log {
      font-weight: bold;
    }
    .notification-not-read {
      background-color: var(--lite);
    }
  
    .notification-not-read:hover {
      background-color: white;
    }
  
    .notification-read:hover {
      background-color: var(--lite);
    }
    
    .notification-read {
      background-color: white;
    }
    .buzz-notification-dropdown-list {
      cursor: pointer;
      // min-height: 110px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
      padding: 0px 10px 10px 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      overflow-wrap: anywhere;

      .notification-action {
        color: var(--primary);
      }
  
      .notification-dot {
        width: 100%;
        height: 10px;
      }

      &:nth-child(2){
        margin-top: 44px;
      }
    }
  
    .notification-description {
      gap: 10px;
      padding-left: 8px;
    }
    .notification-body {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  
    .notification-date {
      color: #A6A6A6;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 53px;
      margin-right: 16px;
    }
  
    .notification-mark-all-as-read {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  
    .notification-mark-all-as-read:hover {
      cursor: pointer;
    }
  
    .notification-range {
      display: flex;
      gap: 10px;
  
      .notification-range-select-container {
        color: #A6A6A6;
        border: none;
        cursor: pointer;
      }
    }
  
    .empty-notifications {
      align-self: center;
      margin-top: 170px;
    }
  }
  
  :root .notification-list {
  @media (max-width: 768px) {
      .buzz-notification-list {
        width: calc(100% - 10px);
      }
    }
  }